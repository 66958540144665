import React, { Component } from 'react';
import { SidebarPortal, BlockDataForm, Icon } from '@plone/volto/components';
import { Button, Popup } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import showSVG from '@plone/volto/icons/show.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import codeSVG from '@plone/volto/icons/code.svg';
import indentSVG from '@plone/volto/icons/indent.svg';

const Editor = loadable(() => import('react-simple-code-editor'));

const messages = defineMessages({
  source: { id: 'Source', defaultMessage: 'Source' },
  preview: { id: 'Preview', defaultMessage: 'Preview' },
  placeholder: { id: '<p>Add some HTML here</p>', defaultMessage: '<p>Add some HTML here</p>' },
  prettier: { id: 'Prettify your code', defaultMessage: 'Prettify your code' },
  clear: { id: 'Clear', defaultMessage: 'Clear' },
  code: { id: 'Code', defaultMessage: 'Code' },
});

class HtmlBlockEdit extends Component {
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    editable: PropTypes.bool,
  };

  static defaultProps = { editable: true };

  constructor(props) {
    super(props);
    this.state = { isPreview: false };
    this.onChangeCode = this.onChangeCode.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onCodeEditor = this.onCodeEditor.bind(this);
  }

  onChangeCode(code) {
    this.props.onChangeBlock(this.props.block, { ...this.props.data, html: code });
  }

  onPreview = async () => {
    try {
      const code = (
        await this.props.prettierStandalone.format(this.getValue(), {
          parser: 'html',
          plugins: [this.props.prettierParserHtml],
        })
      ).trim();
      this.setState({ isPreview: !this.state.isPreview }, () => this.onChangeCode(code));
    } catch (ex) {
      console.error('Error formatting HTML', ex);
    }
  };

  onPrettify = async () => {
    try {
      const code = (
        await this.props.prettierStandalone.format(this.getValue(), {
          parser: 'html',
          plugins: [this.props.prettierParserHtml],
        })
      ).trim();
      this.onChangeCode(code);
    } catch (ex) {
      console.error('Error formatting HTML', ex);
    }
  };

  onCodeEditor() {
    this.setState({ isPreview: !this.state.isPreview });
  }

  getValue() {
    return this.props.data.html || '';
  }

  render() {
    const { data, onChangeBlock, block, selected } = this.props;
    const placeholder = this.props.intl.formatMessage(messages.placeholder);
    const value = this.getValue();

    return (
      <>
        {/* 🔹 HTML Editor with Preview & Toolbar */}
        {selected && value && (
          <div className="toolbar">
            <Popup
              trigger={
                <Button icon basic active={!this.state.isPreview} onClick={this.onCodeEditor}>
                  <Icon name={codeSVG} size="24px" />
                </Button>
              }
              content={this.props.intl.formatMessage(messages.code)}
              position="top center"
              size="mini"
            />
            <Popup
              trigger={
                <Button icon basic active={this.state.isPreview} onClick={this.onPreview}>
                  <Icon name={showSVG} size="24px" />
                </Button>
              }
              content={this.props.intl.formatMessage(messages.preview)}
              position="top center"
              size="mini"
            />
            <Popup
              trigger={
                <Button icon basic onClick={this.onPrettify}>
                  <Icon name={indentSVG} size="24px" />
                </Button>
              }
              content={this.props.intl.formatMessage(messages.prettier)}
              position="top center"
              size="mini"
            />
            <Popup
              trigger={
                <Button.Group>
                  <Button icon basic onClick={() => this.onChangeCode('')}>
                    <Icon name={clearSVG} size="24px" color="#e40166" />
                  </Button>
                </Button.Group>
              }
              content={this.props.intl.formatMessage(messages.clear)}
              position="top center"
              size="mini"
            />
          </div>
        )}

        {this.state.isPreview ? (
          <div dangerouslySetInnerHTML={{ __html: value }} />
        ) : (
          <Editor
            value={value}
            readOnly={!this.props.editable}
            placeholder={placeholder}
            onValueChange={(code) => this.onChangeCode(code)}
            highlight={
              this.props.prismCore?.highlight
                ? (code) => this.props.prismCore.highlight(code, this.props.prismCore.languages.html, 'html')
                : () => {}
            }
            padding={8}
            className="html-editor"
          />
        )}

        {/* 🔹 Sidebar for CSS & JS Selection */}
        {selected && (
          <SidebarPortal selected={selected}>
            <BlockDataForm
              schemaEnhancer={(schema) => schema}
              onChangeField={(id, value) => {
                const updatedValue = Array.isArray(value) ? value : value ? [value] : [];
                onChangeBlock(block, { ...data, [id]: updatedValue });
              }}
              formData={data}
            />
          </SidebarPortal>
        )}
      </>
    );
  }
}

const withPrismMarkup = (WrappedComponent) => (props) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    import('prismjs/components/prism-markup').then(() => setLoaded(true));
  }, []);
  return loaded ? <WrappedComponent {...props} /> : null;
};

export default compose(
  injectLazyLibs(['prettierStandalone', 'prettierParserHtml', 'prismCore']),
  withPrismMarkup,
  injectIntl,
)(HtmlBlockEdit);
