import React, { useEffect, useState } from 'react';

const HtmlBlockView = ({ data = {} }) => {
  console.log('HTML Block Data:', JSON.stringify(data, null, 2)); // ✅ Full debug output

  const [resolvedCSS, setResolvedCSS] = useState([]);
  const [resolvedJS, setResolvedJS] = useState([]);

  // ✅ Function to transform the extracted URL into Plone’s correct download URL
  const getDownloadURL = (fileURL) => {
    if (!fileURL || typeof fileURL !== 'string') return null;

    // Ensure the fileURL does not already contain @@download
    if (fileURL.includes('/@@download')) {
      return fileURL;
    }

    // Extract the filename from the URL
    const urlParts = fileURL.split('/');
    const filename = urlParts[urlParts.length - 1];

    // ✅ Construct the correct Plone download URL
    return `${fileURL}/@@download/file/${filename}`;
  };

  // ✅ Function to extract the file URL correctly
  const extractFilePath = (fileObject) => {
    if (!fileObject) return null;

    if (typeof fileObject === 'string') {
      console.log('Using direct string path:', fileObject);
      return getDownloadURL(fileObject); // ✅ Convert to download URL
    }

    if (Array.isArray(fileObject.file) && fileObject.file.length > 0) {
      const fileURL = fileObject.file[0]['@id']; // ✅ Extract first file from array
      console.log('Extracted from array:', fileURL);
      return getDownloadURL(fileURL); // ✅ Convert to download URL
    }

    if (fileObject['@id']) {
      console.log('Falling back to @id:', fileObject['@id']);
      return getDownloadURL(fileObject['@id']); // ✅ Convert to download URL
    }

    console.warn('Invalid file format:', fileObject);
    return null;
  };

  useEffect(() => {
    if (data.customCSS) {
      setResolvedCSS(data.customCSS.map(extractFilePath).filter(Boolean));
    }
    if (data.customJS) {
      setResolvedJS(data.customJS.map(extractFilePath).filter(Boolean));
    }
  }, [data.customCSS, data.customJS]);

  useEffect(() => {
    const addedCSS = [];
    const addedJS = [];

    resolvedCSS.forEach((cssURL) => {
      if (cssURL) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = cssURL;
        link.type = 'text/css';
        document.head.appendChild(link);
        addedCSS.push(link);
        console.log('Loaded CSS:', cssURL);
      }
    });

    resolvedJS.forEach((jsURL) => {
      if (jsURL) {
        const script = document.createElement('script');
        script.src = jsURL;
        script.async = false;
        document.body.appendChild(script);
        addedJS.push(script);
        console.log('Loaded JS:', jsURL);
      }
    });

    return () => {
      addedCSS.forEach((link) => document.head.removeChild(link));
      addedJS.forEach((script) => document.body.removeChild(script));
    };
  }, [resolvedCSS, resolvedJS]);

  return <div dangerouslySetInnerHTML={{ __html: data.html || '' }} />;
};

export default HtmlBlockView;
