import HtmlBlockView from './customizations/components/manage/Blocks/HTML/View';
import HtmlBlockEdit from './customizations/components/manage/Blocks/HTML/Edit';

const applyConfig = (config) => {
  config.settings.isMultilingual = false;
  config.settings.supportedLanguages = ['en'];
  config.settings.defaultLanguage = 'en';
  config.blocks.themes = [
    {
      style: {
        '--theme-color': 'white',
        '--theme-high-contrast-color': 'black',
        '--theme-foreground-color': 'black',
        '--theme-low-contrast-foreground-color': 'var(--grey)',
      },
      name: 'default',
      label: 'Default',
    },
    {
      style: {
        '--theme-color': 'var(--lightbrown)',
        '--theme-high-contrast-color': 'black',
        '--theme-foreground-color': 'black',
        '--theme-low-contrast-foreground-color': 'var(--mocha)',
      },
      name: 'lightestbrown',
      label: 'Lightest Brown',
    },
    {
      style: {
        '--theme-color': 'var(--lightestbrown)',
        '--theme-high-contrast-color': 'black',
        '--theme-foreground-color': 'black',
        '--theme-low-contrast-foreground-color': 'var(--mocha)',
      },
      name: 'lightbrown',
      label: 'Light Brown',
    },
    {
      style: {
        '--theme-color': 'var(--lightgrey)',
        '--theme-high-contrast-color': 'black',
        '--theme-foreground-color': 'black',
        '--theme-low-contrast-foreground-color': 'var(--grey)',
      },
      name: 'lightgrey',
      label: 'Light Grey',
    },
    {
      style: {
        '--theme-color': 'var(--darkgreen)',
        '--theme-high-contrast-color': 'white',
        '--theme-foreground-color': 'white',
        '--theme-low-contrast-foreground-color': 'var(--lightgrey)',
        '--link-foreground-color': 'white',
      },
      name: 'green',
      label: 'Green',
    },
    {
      style: {
        '--theme-color': 'var(--mocha)',
        '--theme-high-contrast-color': 'white',
        '--theme-foreground-color': 'white',
        '--theme-low-contrast-foreground-color': 'var(--lightestbrown)',
        '--link-foreground-color': 'white',
      },
      name: 'mocha',
      label: 'Mocha',
    },
    {
      style: {
        '--theme-color': 'var(--blue)',
        '--theme-high-contrast-color': 'white',
        '--theme-foreground-color': 'white',
        '--theme-low-contrast-foreground-color': 'var(--lightgrey)',
        '--link-foreground-color': 'white',
      },
      name: 'blue',
      label: 'Blue',
    },
    {
      style: {
        '--theme-color': 'var(--red)',
        '--theme-high-contrast-color': 'white',
        '--theme-foreground-color': 'white',
        '--theme-low-contrast-foreground-color': 'var(--lightgrey)',
        '--link-foreground-color': 'white',
      },
      name: 'red',
      label: 'Red',
    },
  ];

  // ✅ Extend the existing HTML block safely
  config.blocks.blocksConfig.html = {
    ...config.blocks.blocksConfig.html,
    view: HtmlBlockView,
    edit: HtmlBlockEdit, // ✅ Make sure this is pointing to your custom editor
    schemaEnhancer: (schema) => {
      const newSchema = { ...schema };

      // Ensure fieldsets exist
      if (!newSchema.fieldsets) {
        newSchema.fieldsets = [
          {
            id: 'default',
            title: 'Default',
            fields: [],
          },
        ];
      }

      // Ensure fields exist in fieldsets[0]
      if (!newSchema.fieldsets[0].fields) {
        newSchema.fieldsets[0].fields = [];
      }

      // Add new fields only if they are not already present
      if (!newSchema.fieldsets[0].fields.includes('customCSS')) {
        newSchema.fieldsets[0].fields.push('customCSS');
      }
      if (!newSchema.fieldsets[0].fields.includes('customJS')) {
        newSchema.fieldsets[0].fields.push('customJS');
      }

      // Ensure required fields exist
      if (!newSchema.required) {
        newSchema.required = [];
      }

      // ✅ Use `object_list` for selecting multiple files
      newSchema.properties = {
        ...newSchema.properties,
        customCSS: {
          title: 'Select CSS Files',
          widget: 'object_list',
          schema: {
            title: 'CSS File',
            fieldsets: [{ id: 'default', title: 'Default', fields: ['file'] }],
            properties: {
              file: {
                title: 'File',
                widget: 'object_browser',
                mode: 'link',
                allowExternals: false,
              },
            },
            required: ['file'],
          },
        },
        customJS: {
          title: 'Select JS Files',
          widget: 'object_list',
          schema: {
            title: 'JS File',
            fieldsets: [{ id: 'default', title: 'Default', fields: ['file'] }],
            properties: {
              file: {
                title: 'File',
                widget: 'object_browser',
                mode: 'link',
                allowExternals: false,
              },
            },
            required: ['file'],
          },
        },
      };

      return newSchema;
    },
  };

  //Add additional volto-columns-block colours here

  config.blocks.blocksConfig.columnsBlock.available_colors = [
    // '#B80000',
    // '#DB3E00',
    // '#FCCB00',
    // '#008B02',
    // '#006B76',
    // '#1273DE',
    // '#004DCF',
    // '#5300EB',
    '#EAE7E0',
    '#D4D9DC',
    '#1252a5',
    '#EFEFEF',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#D4C4FB',
  ];

  
  return config;
};

export default applyConfig;
